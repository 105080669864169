<template>
  <div class="p-1">
    <div class="contact card">
      <div class="row no-gutters">
        <div class="col-3">
          <Avatar v-bind:user="user" class="card-img" />
        </div>
        <div class="col-9">
          <div class="card-body" v-bind:class="{ small: collapsed }">
            <h5 class="card-title">
              {{ name }}
              <img
                v-bind:src="
                  '/images/flags/' + user.country.toLowerCase() + '.png'
                "
                v-bind:alt="'from ' + user.country"
              />
            </h5>
            <p class="blockquote-footer">
              {{ user.jobPosition.name + " @ " + user.company.name }}
            </p>
            <div v-if="user.isMentor">
              <img
                src="/images/themes/ET/grade.svg"
                style="height: 1.6em;"
                v-for="i in user.mentorLevel"
                v-bind:key="i"
              />
            </div>
            <p class="card-text">
              {{ user.description }}
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Avatar from "@/components/Avatar.vue";

export default {
  name: "ContactCard",
  components: {
    Avatar
  },
  data: function() {
    return {
      collapsed: true
    };
  },
  props: {
    user: {
      type: Object,
      default: null
    }
  },
  computed: {
    name() {
      return this.user.firstName + " " + this.user.lastName;
    }
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.card-img {
  height: 6.4em;
}

.card-title img {
  height: 1.5em;
}

.card-body {
  margin-left: -5em;
  padding-left: 5em;
  padding-right: 0;
}

.card-text {
  margin-left: -5.7em;
}

.small {
  overflow: hidden;
}

.fadedown {
  background-image: linear-gradient(
    to bottom,
    rgba(255, 255, 255, 0),
    rgba(255, 255, 255, 1)
  );
}
</style>
