<template>
  <div class="pmwizard">
    <HeaderBack backto="/peermentoring" />
    <portal to="topbar-text">
      <h3>Peer Mentoring</h3>
      <p>New Request</p>
    </portal>

    <SingleFilter
      class="filter"
      v-for="(f, index) in activeFilters"
      v-bind="f"
      v-model="f.selected"
      v-bind:key="index"
      v-show="step == 0"
      v-on:setSelected="nextFilter(index)"
    />
    <button
      class="btn btn-primary"
      v-on:click="step = step > 0 ? step - 1 : step + 1"
      v-show="activeFilters[0].selected >= 0 && step <= 1"
      v-html="step > 0 ? 'Modify my Filters' : 'Search'"
    >
      Search
    </button>
    <div id="contact-list" class="container" v-if="step == 1">
      <h2>I choose my mentor.</h2>
      <div class="row">
        <button
          class="btn btn-light col-md-4"
          v-for="(contact, i) in filteredContacts"
          v-on:click="selectContact(contact)"
          v-bind:key="i"
        >
          <ContactCard v-bind:user="contact" />
        </button>
      </div>
    </div>
    <div v-if="step == 2">
      <h4>
        Requesting feedback from
        {{ selectedContact.firstName + " " + selectedContact.lastName }}
        {{
          activeFilters[0].selected != 0
            ? "(" + selectedContact.company.name + ")"
            : ""
        }}
        <Avatar v-bind:user="selectedContact" class="avatar" />
      </h4>
      <SingleFilter
        class="filter"
        v-for="(f, index) in PMoptions"
        v-bind="f"
        v-model="f.selected"
        v-bind:key="index"
      />
      <br />
      <button class="btn btn-primary">
        Request Mentoring
      </button>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import HeaderBack from "@/components/HeaderBack.vue";
import ContactCard from "@/components/ContactCard.vue";
import SingleFilter from "@/components/SingleFilter.vue";
import Avatar from "@/components/Avatar.vue";
import api from "@/services/api.js";

export default {
  name: "PeerMentoring",
  components: {
    HeaderBack,
    ContactCard,
    SingleFilter,
    Avatar
  },
  beforeMount: function() {
    api.getMentors().then(res => {
      this.contacts = res;
      for (let f = 0; f < this.filters.length; f++) {
        if (this.filters[f].options_provider) {
          if (typeof this.filters[f].options_provider === "object") {
            this.filters[f].options_provider.getter().then(res => {
              this.filters[f].options = res.map(
                this.filters[f].options_provider.mapFunc
              );
            });
          } else if (typeof this.filters[f].options_provider == "string") {
            this.filters[f].options = this.getFilterOptions(
              this.filters[f].options_provider
            );
          } else {
            console.error("Filter " + f + " has a bad options_provider!");
          }
        }
      }
    });
  },
  data: function() {
    return {
      currentFilter: 0,
      step: 0,
      selectedContact: {},
      contacts: null,
      filters: [
        {
          type: "button",
          title: "I'm looking for feedback from",
          options: [
            {
              name: "<b>inside</b> my company",
              func: contact =>
                !!this.$store.state.user.company &&
                !!contact.company &&
                this.$store.state.user.company.name == contact.company.name
            },
            {
              name: "<b>outside</b> my company",
              func: contact =>
                !this.$store.state.user.company ||
                !contact.company ||
                this.$store.state.user.company.name != contact.company.name
            },
            {
              name: "<b>I'm happy either way</b>",
              func: () => true
            },
            {
              name: "a <b>professional</b> coach",
              separator: true,
              func: contact =>
                !!contact.company && contact.company.name == "EnglishTonic"
            }
          ]
        },
        {
          type: "dropdown",
          placeholder: "Job/position",
          title: "Job/position",
          options_provider: {
            getter: api.getJobs,
            mapFunc: function(data) {
              return {
                name: data.name,
                func: c => c.jobPosition.code === data.code
              };
            }
          }
        },
        {
          type: "dropdown",
          title: "Country",
          placeholder: "Choose a country",
          options_provider: "country"
        }
      ],
      PMoptions: [
        {
          type: "button",
          title: "Type of feedback",
          options: [
            {
              name: "Sandwich Feedback"
            },
            {
              name: "360-degree Feedback"
            }
          ]
        },
        {
          type: "button",
          title: "Type of content",
          options: [
            {
              name: "Presentation"
            },
            {
              name: "Pitch"
            },
            {
              name: "Meeting"
            },
            {
              name: "Other"
            }
          ]
        }
      ]
    };
  },
  methods: {
    nextFilter: function(filter) {
      if (this.currentFilter == filter) this.currentFilter += 1;
    },
    selectContact: function(contact) {
      this.selectedContact = contact;
      this.step += 1;
    },
    getFilterOptions: function(key) {
      let res = this.contacts.map(function(contact) {
        return {
          name: contact[key].name,
          func: c => c[key].name === contact[key].name
        };
      });
      // Ensure unicity of elements in the list
      res = res.filter(function(value, index, self) {
        var findIndex = element => element.name == value.name;
        return self.findIndex(findIndex) === index;
      });
      return res;
    }
  },
  computed: {
    activeFilters: function() {
      return this.filters.slice(0, this.currentFilter + 1);

      /* var unselected = [];
       * var selected = [];
       * var i;
       * for (i in this.filters) {
       *   if (i <= this.currentFilter) {
       *     let f = this.filters[i];
       *     if (f.selected >= 0) selected.push(f);
       *     else unselected.push(f);
       *   }
       * }
       * return [...selected, ...unselected]; */
    },
    filteredContacts: function() {
      let res = this.contacts;
      for (let i = 0; i < this.activeFilters.length; i++) {
        let f = this.activeFilters[i];
        if (f.selected >= 0 && !!f.options[f.selected].func) {
          res = res.filter(f.options[f.selected].func);
        }
      }
      return res;
    }
  }
};
</script>

<style scoped lang="scss">
.pmwizard {
  @extend .text-center;
  .filter {
    animation: fade-in-right 0.5s;
  }
}

.avatar {
  height: 3em;
  @extend .d-inline-block;
}
</style>
