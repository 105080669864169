<template>
  <div class="container">
    <Flipper v-bind:flip-key="this.selected >= 0">
      <div class="row" v-bind:class="categoryClass">
        <Flipped flip-id="title">
          <div class="question">
            {{ title }}
          </div>
        </Flipped>
        <Flipped flip-id="content">
          <div class="answers">
            <div id="buttons" v-if="type == 'button'">
              <a
                v-for="(item, i) in options"
                v-bind:key="item.title"
                class="btn btn-outline-secondary"
                v-bind:class="{
                  active: selected == i,
                  'btn-sm': selected >= 0,
                  separated: selected < 0 && item.separator
                }"
                v-show="selected < 0 || selected == i"
                href="#"
                v-html="item.name"
                v-on:click="setFilter(i)"
              >
              </a>
            </div>
            <div id="dropdown" v-else-if="type == 'dropdown'">
              <input
                v-bind:list="'dropdown' + _uid"
                v-bind:id="'input' + _uid"
                v-bind:name="'input' + _uid"
                v-bind:placeholder="placeholder"
                v-on:input="datalistSetFilter"
                v-model="inputContent"
                v-show="selected < 0"
                type="search"
              />
              <datalist v-bind:id="'dropdown' + _uid" v-show="selected < 0">
                <option
                  v-for="item in options"
                  v-bind:key="item.name"
                  v-bind:value="item.name"
                />
              </datalist>

              <a
                v-on:click="reset"
                class="btn btn-outline-secondary active"
                v-if="selected >= 0"
              >
                {{ options[selected].name }}
              </a>
            </div>
          </div>
        </Flipped>
      </div>
    </Flipper>
  </div>
</template>

<script>
import { Flipper, Flipped } from "vue-flip-toolkit";
/* import Dropdown from "@/components/Dropopdown.vue";
 *  */
export default {
  name: "SingleFilter",
  components: {
    Flipped,
    Flipper
    /* Dropdown */
  },
  model: {
    prop: "selected",
    event: "setSelected"
  },
  props: {
    title: String,
    type: String,
    options: Array,
    placeholder: String,
    selected: {
      type: Number,
      default: -1
    }
  },
  data() {
    return {
      inputContent: ""
    };
  },
  computed: {
    categoryClass: function() {
      if (this.selected >= 0) return "categoryset";
      else return "categorylist";
    }
  },
  methods: {
    datalistSetFilter() {
      for (let i = 0; i < this.options.length; i++) {
        if (this.options[i].name == this.inputContent) {
          this.emit(i);
        }
      }
    },
    setFilter(selected) {
      if (selected >= 0) {
        if (this.selected == selected) {
          this.reset();
        } else {
          this.emit(selected);
        }
      }
    },
    reset: function() {
      console.log("reset");
      this.inputContent = "";
      this.emit(-1);
    },
    emit: function(selected) {
      this.$emit("setSelected", selected);
    }
  }
};
</script>

<style scoped lang="scss">
.categorylist {
  a {
    @extend .d-block;
    @extend .mx-auto;
    @extend .my-3;
    width: max-content;

    &.separated {
      margin-top: 3rem !important;
    }
  }

  div {
    @extend .my-3;
    @extend .col-12;
  }
  .question {
    @extend .h5;
  }
}
.answers {
  text-align: center;
}
.categoryset {
  & > div {
    @extend .col-6;
  }
}
#dropdown {
  margin: auto !important;
  width: fit-content;
}
</style>
