<template>
  <portal to="topbar-menu">
    <router-link v-bind:to="backto" class="btn btn-primary">
      <img
        alt="back"
        src="/images/themes/ET/survival/back.svg"
        style="height: 2em;"
      />
    </router-link>
  </portal>
</template>

<script>
export default {
  name: "HeaderBack",
  props: {
    backto: String
  }
};
</script>
